import React from "react";
import styles from "./Card.module.css";
import jinpeng from "./../../images/jin02.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTelegram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faChartArea, faSquare } from "@fortawesome/free-solid-svg-icons";
import Eye from "../Eye/Eye.module";
import CoinMarket from "../CoinMarket/CoinMarket.module";
const Card = () => {
  return (
    <div className={styles.card}>
      {/* <div className={styles.toppic}>
        <img src={jinpeng} />
      </div> */}
      <Eye />
      <div className={styles.descs}>
        Her is my koin - $JIN, de kryptocurenci for Chaina we ken beliv in. YES,
        WI KEN! chenge financ, help ppl invest in de futur. This is HOP for
        memekoin spac you hev been weit for.
      </div>
      <div id="cards" className={styles.capart}>
        CA: GgzJWhQWTpv2VHqvJnx5uEWQzNkfQSncDEWUdgQk4fvh
      </div>
      <CoinMarket />
      <div className={styles.containbtns}>
        <a href="https://t.me/JinpengSol">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faTelegram} />
            Telegram Channel
          </div>
        </a>
        <a href="https://t.me/jinpenggroup">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faTelegram} />
            Telegram
          </div>
        </a>
        <a href="https://x.com/jinpengsol">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faXTwitter} />
            Twitter
          </div>
        </a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/7ax1wVCoRr192G5cjS3pvdqkbJfqEsecir874424AkpR?t=1713557077500">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faSquare} />
            Dex Tools
          </div>
        </a>
        <a href="https://dexscreener.com/solana/7ax1wvcorr192g5cjs3pvdqkbjfqesecir874424akpr">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faChartArea} />
            Dexscreener
          </div>
        </a>
      </div>
    </div>
  );
};

export default Card;
