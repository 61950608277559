import React from "react";
import styles from "./Header.module.css";
import jinpeng from "./../../images/jin02.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillTransfer,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <header>
      <div className={`app-container ${styles.header}`}>
        <div className={styles.lefticon}>
          <img src={jinpeng} />
          $JIN
        </div>

        <div className={styles.rightbox}>
          <div
            onClick={() => scrollToSection("cards")}
            className={styles.buynow}
          >
            Social Media
            <FontAwesomeIcon icon={faHashtag} />
          </div>

          <a href="https://jup.ag/swap/JIN-SOL">
            <div className={styles.buynow}>
              Buy on jupiter
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
